import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon } from "antd";
import config from "@/commons/config-hoc";
import Link from "../page-link";
import Logo from "../logo";
import HeaderUser from "../header-user";
import HeaderMenu from "../header-menu";
import { Popover, Badge, Empty } from "antd";
// import HeaderFullScreen from '../header-full-screen';
// import ThemeColorPicker from '../header-color-picker';
import { connect } from "../../models/index";
import { PAGE_FRAME_LAYOUT } from "@/models/settings";
import Breadcrumb from "../breadcrumb";
import { Pagination } from "@/library/components";
import "./style.less";

@connect((state) => {
    const { menus, topMenu } = state.menu;
    const {
        show: showSide,
        width,
        collapsed,
        collapsedWidth,
        dragging,
    } = state.side;
    const { breadcrumbs } = state.page;
    const { pageFrameLayout } = state.settings;

    return {
        menus,
        topMenu,

        showSide,
        sideWidth: width,
        sideCollapsed: collapsed,
        sideCollapsedWidth: collapsedWidth,
        sideDragging: dragging,
        breadcrumbs,

        layout: pageFrameLayout,
    };
})
@config({
    ajax: true,
})
export default class Header extends Component {
    static propTypes = {
        layout: PropTypes.string,
        theme: PropTypes.string,
    };

    static defaultProps = {
        layout: PAGE_FRAME_LAYOUT.SIDE_MENU, // top-side-menu top-menu side-menu
        theme: "default", // default dark
    };
    state = {
        visible: false,
        unreadCount: 0,
        messageList: [],
        total: 0,
        page: 0,
        page_size: 10,
    };
    componentDidMount() {
        this._init();
    }
    _init = () => {
        this.getUnreadCount();
        this.getMessageList();
    };
    getUnreadCount = () => {
        this.props.ajax.post(`/common/unReadCount`, {}).then((res) => {
            const data = res.data;
            this.setState({
                unreadCount: data.message_count,
            });
        });
    };
    getMessageList = () => {
        const { page, page_size } = this.state;
        const params = {
            page,
            page_size,
        };
        this.props.ajax.post(`/common/messagePage`, { params }).then((res) => {
            const messageList = res?.data.list || [];
            const total = res?.data.totalCount || 0;

            this.setState({ messageList, total });
        });
    };
    setAllRead = () => {
        const successTip = "全部设置已读成功";
        this.props.ajax
            .post(`/common/readMessage`, {}, { successTip })
            .then((res) => {
                this._init();
            });
    };
    handleToggle = () => {
        const { sideCollapsed } = this.props;
        this.props.action.side.setCollapsed(!sideCollapsed);
    };
    handleVisibleChange = (visible) => {
        this.setState({ visible });
    };
    render() {
        let {
            layout,
            menus, // 所有的菜单数据
            topMenu, // 当前页面选中菜单的顶级菜单
            sideCollapsed,
            sideCollapsedWidth,
            sideWidth,
            sideDragging,
            breadcrumbs,
            children,
        } = this.props;
        const { messageList, total, page, page_size, unreadCount } = this.state;
        sideWidth = sideCollapsed ? sideCollapsedWidth : sideWidth;

        const isTopSideMenu = layout === PAGE_FRAME_LAYOUT.TOP_SIDE_MENU;
        const isTopMenu = layout === PAGE_FRAME_LAYOUT.TOP_MENU;
        const isSideMenu = layout === PAGE_FRAME_LAYOUT.SIDE_MENU;
        const showToggle = isTopSideMenu || isSideMenu;
        const showMenu = isTopSideMenu || isTopMenu;

        let topMenus = menus;
        if (isTopSideMenu) {
            topMenus =
                menus &&
                menus.map((item) => ({
                    key: item.key,
                    text: item.text,
                    path: item.path,
                    icon: item.icon,
                }));
        }
        if (isTopMenu) {
            topMenus = menus;
        }

        let transitionDuration = sideDragging ? "0ms" : "300ms";

        const theme =
            this.props.theme ||
            (isTopSideMenu || isSideMenu ? "default" : "dark");
        const contentList =
            messageList.length !== 0 ? (
                <div className="message-wrapper">
                    {messageList.map((item,index) => {
                        return (
                            <div className="message-list" key={index}>
                                <div
                                    className="message-list-title"
                                    style={{
                                        color:
                                            item.is_read === 1
                                                ? "#888"
                                                : "#333",
                                    }}
                                >
                                    {item.content}
                                </div>
                                <div style={{ color: "#888" }}>
                                    {item.create_time}
                                </div>
                            </div>
                        );
                    })}
                    <Pagination
                        total={total}
                        pageNum={page}
                        pageSize={page_size}
                        onPageNumChange={(page) =>
                            this.setState({ page }, this.getMessageList)
                        }
                        onPageSizeChange={(page_size) =>
                            this.setState(
                                { page_size, page: 1 },
                                this.getMessageList
                            )
                        }
                    />
                </div>
            ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            );
        return (
            <div id="header" styleName="header" data-theme={theme}>
                <div
                    styleName="logo-container"
                    id="logo-container"
                    style={{ flex: `0 0 ${sideWidth}px`, transitionDuration }}
                >
                    <Link to="/">
                        <Logo
                            min={sideCollapsed}
                            title={
                                <div>
                                    <span>Uni星球医生端管理系统</span>
                                </div>
                            }
                        />
                    </Link>
                </div>
                {showToggle ? (
                    <Icon
                        className="header-trigger"
                        styleName="trigger"
                        type={sideCollapsed ? "menu-unfold" : "menu-fold"}
                        onClick={this.handleToggle}
                        style={
                            theme === "dark"
                                ? { color: "#fff", backgroundColor: "#222" }
                                : null
                        }
                    />
                ) : null}
                {children ? (
                    <div styleName="center">{children}</div>
                ) : (
                    <div styleName="center">
                        {showMenu ? (
                            <HeaderMenu
                                theme={theme}
                                dataSource={topMenus}
                                selectedKeys={[topMenu && topMenu.key]}
                            />
                        ) : null}
                        {isSideMenu ? (
                            <div style={{ marginLeft: 16 }}>
                                <Breadcrumb
                                    theme={theme}
                                    dataSource={breadcrumbs}
                                />
                            </div>
                        ) : null}
                    </div>
                )}

                <div styleName="right">
                    {/* <HeaderFullScreen styleName="action" className="header-action"/>
                    <ThemeColorPicker styleName="action" className="header-action"/> */}
                    <Popover
                        content={contentList}
                        title={
                            <div className="d-title">
                                <div>通知</div>
                                {unreadCount !== 0 && (
                                    <div
                                        className="d-read"
                                        onClick={this.setAllRead}
                                    >
                                        全部已读
                                    </div>
                                )}
                            </div>
                        }
                        trigger="click"
                        visible={this.state.visible}
                        onVisibleChange={this.handleVisibleChange}
                        overlayStyle={{ width: 300 }}
                        getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                        }
                        arrowPointAtCenter
                        overlayClassName="popoverCustomClass"
                    >
                        <div className="right-info">
                            <Badge count={unreadCount} overflowCount={9}>
                                <Icon
                                    type="bell"
                                    styleName="action"
                                    className="header-action"
                                    style={{ fontSize: 16 }}
                                />
                            </Badge>
                        </div>
                    </Popover>

                    <HeaderUser
                        styleName="action"
                        className="header-action"
                        theme={theme}
                    />
                </div>
            </div>
        );
    }
}
